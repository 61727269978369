<template>
   	<div class="flex items-center justify-center bg-gray-50 pt-4 sm:pt-12 pb-12 px-4 sm:px-6 lg:px-8">
		<div class="max-w-3xl w-full">
			
		
			<div>
				<h2 class="mt-6 text-center text-2xl sm:text-3xl font-extrabold text-accent">
					Instruccciones
				</h2>
				<div class="mt-4 sm:px-4">
					Hemos preparado un divertido juego que pone a prueba tus conocimientos; el objetivo es responder correctamente a la mayor cantidad de preguntas que hemos preparado para ti, al obtener la mayor cantidad de puntos podrás demostrar quien es el mejor navegante.
				</div>
			</div>

			<img class="mt-6 rounded-lg" :src="require('@/assets/game/faro.jpg')" alt="">

			<div>
				<h2 class="mt-8 text-center text-xl sm:text-2xl font-extrabold text-accent bg-blue-gray-100 py-2 rounded-md">
					Mecánica
				</h2>
				<div class="mt-4 space-y-2 sm:px-4">
					<p>Tu barco está a la deriva y solo tus conocimientos podrán llevarlo a su destino, el viaje comienza en una isla desierta yes tu deber superar 3 fases durante el recorrido, en cada fase deberás responder 5 preguntas de opción múltiple.</p>

					<p>Si contestas correctamente en el primer intento cada pregunta tendrá un valor de 1000 puntos, si respondes de manera incorrecta tendrás una segunda oportunidad de elegir la opción correcta por la mitad de puntos (500), si respondes de manera incorrecta nuevamente se pasará automáticamente a la siguiente pregunta y no recibirás puntos por esa pregunta. La sesión de juego finalizará después de haber respondido las 3 fases de preguntas. Tu puntuación final será la suma de los puntos otorgados por responder correctamente.</p>
				
					<p>El juego consiste de 15 preguntas divididas en 3 fases, para ello tienes 2 semanas antes de que se renueve el ciclo y para que 15 nuevas preguntas estén disponibles con sus respectivos puntos. Permanece atento, ya que cada mes habrá un nivel nuevo y con ello una nueva etapa, que llevará a tu navegación hasta su meta final (el faro de SAYL’s y tierra firme).</p>

					<p>También podrás subir un caso clínico por el que se sumarán puntos adicionales a tu récord.</p>

					<p>Habrán importantes premios para los ganadores, así que, <span class="font-semibold text-accent">¡Mucha suerte y que gane el mejor!</span></p>
				</div>
			</div>
        </div>
    </div>
</template>